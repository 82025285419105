@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './assets/scss/variables';

.chat-bot {
	font-family: Inter;
	box-sizing: border-box;
	position: fixed !important;
	right: 60px;
	bottom: 100px;
	z-index: 99999;
	.bot-popup {
		width: 92px;
		height: 56px;
		border-radius: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	.bot-chat {
		width: 436px;
		height: 519px;
		box-shadow: 32.513px 32.513px 135.471px rgba(0, 0, 0, 0.15);
		position: absolute;
		right: 0;
		bottom: 84px;
		background-color: $universalWhite;
		border-radius: 4px;
		.bot-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: $universalWhite;
			background-color: $headerBackground;
			padding: 13px 25px 13px 30px;
			border-radius: 4px 4px 0 0;
			.chat-box-header {
				display: flex;
				align-items: center;
				gap: 11px;
				.chat-box-heading {
					font-weight: 700;
					font-size: 16.73px;
					line-height: 20px;
					letter-spacing: -0.02em;
				}
				.chat-box-subHeading {
					font-weight: 400;
					font-size: 12px;
					line-height: 13px;
					letter-spacing: -0.02em;
				}
			}
		}
		.bot-body {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: calc(100% - 93px);
			.questions {
				flex-wrap: wrap;
				margin: 16px 20px;
				display: flex;
				align-items: center;
				gap: 20px;
			}
			.question {
				box-sizing: border-box;
				padding: 6px 10.286px 8px 6.714px;
				border-radius: 4px;
				background-color: rgba(225, 225, 225, 0.27);
				margin: 0;
				font-size: 10px;
				font-weight: 400;
				line-height: 12px;
				height: 26px;
				p {
					margin: 0;
				}
			}
			.emailAndPhoneWrrapper {
				margin: 24px 20px 16px;
				padding: 15px 16px 10px;
				background: rgba(247, 247, 247, 1);
				height: 205px;
				border-radius: 3px;
				.input-wrapper {
					display: flex;
					flex-direction: column;
					gap: 16px;
					margin-top: 17px;
					width: 100%;
					input {
						border: 1px solid rgba(0, 0, 0, 0.3);
						width: auto;
						padding: 8px 16px;
						color: rgba(0, 0, 0, 0.87);
						font-size: 14px;
						font-weight: 400;
						line-height: 24px;
						letter-spacing: 0.5px;
						background-color: transparent;
						&:focus-visible {
							outline: none;
						}
					}
				}
				.button-wrapper {
					display: flex;
					justify-content: flex-end;
					margin-top: 17px;
				}
			}
			.headingText {
				color: $universalBlack;
				font-size: 15px;
				font-weight: 600;
				letter-spacing: -0.3px;
				margin: 0;
			}
			.input {
				width: 230px;
				height: 40px;
				border-radius: 4px;
				justify-content: space-between;
				font-size: 13px;
				background-color: transparent;
			}
			.msgs-scroller {
				padding: 16px;
				height: calc(100% - 90px);
				overflow-y: auto;
				display: flex;
				flex-direction: column-reverse;
				&::-webkit-scrollbar {
					display: none;
				}
				.label-wrapper {
					display: flex;
					gap: 8px;
					.display-time {
						margin-left: 44px;
					}
				}
				.message-wrapper {
					display: flex;
					gap: 8px;
				}
				.message-sent-wrapper {
					display: flex;
					flex-direction: column-reverse;
					.display-date {
						display: none;
					}
					.display-time {
						display: none;
					}
					.msg-sent {
						&:first-of-type .display-date {
							display: block;
						}
						&:first-of-type .display-time {
							display: block;
						}
					}
				}
				.msg-sent {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					justify-content: end;
					margin-top: 8px;
					p {
						box-sizing: border-box;
						padding: 11px 23px;
						border-radius: 4px;
						background: $textBackground;
						margin: 0;
						font-size: 13px;
						font-weight: 400;
						line-height: 16.2px;
						letter-spacing: -0.02em;
						margin-bottom: 10px;
						word-break: break-word !important;
					}
					label {
						font-size: 12px;
						line-height: 14px;
						letter-spacing: -0.02em;
						color: $labelColor;
						font-weight: 400;
					}
				}
				.message-receive-wrapper {
					display: flex;
					flex-direction: column-reverse;
					.svg-wrapper {
						visibility: hidden;
					}
					.msg-receive {
						&:last-of-type {
							.svg-wrapper {
								visibility: visible;
							}
						}
						&:first-of-type {
							.display-date {
								display: block;
							}
							.display-time {
								display: block;
							}
						}
					}
					.display-date {
						display: none;
					}
					.display-time {
						display: none;
					}
				}
				.msg-receive {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: end;
					margin-top: 8px;
					span {
						box-sizing: border-box;
						padding: 11px 23px;
						border-radius: 4px;
						margin: 0;
						background: $textBackground;
						color: $universalBlack;
						font-size: 13px;
						font-weight: 400;
						line-height: 16.2px;
						letter-spacing: -0.02em;
						margin-bottom: 10px;
						word-break: break-word !important;
						width: -webkit-fit-content;
						a {
							color: #0000ff;
						}
					}
					label {
						font-size: 12px;
						line-height: 14px;
						letter-spacing: -0.02em;
						color: $labelColor;
						font-weight: 400;
					}
				}
			}
			.write-msg {
				margin: 0 16px;
				border: 1px solid $borderColor;
				border-radius: 4px;
				display: flex;
				align-items: center;
				gap: 20px;
				padding-right: 32px;
				input {
					padding: 16px 26px;
					border: none;
					border-radius: 4px;
					width: calc(100% - 60px);
					font-size: 14px;
					background-color: transparent;
					line-height: 18px;
					color: rgba(0, 0, 0, 0.87) !important;
					&:focus {
						border: none;
						outline: none;
					}
					&::-webkit-search-cancel-button {
						display: none;
					}
				}
				.vertical-line {
					margin: 8px 0px;
					width: 1px;
					height: calc(100% - 16px);
					background-color: $borderColor;
				}
			}
			.powered-by {
				font-size: 12px;
				padding: 8px 0 8px 14px;
				color: $universalBlack;
				span {
					color: $poweredByColor;
				}
			}
		}
		.animation {
			animation: fadeUp 1000ms ease-out backwards;
		}
	}
}
.chatbot-button {
	position: fixed;
	bottom: 20px;
	right: 20px;
	background-color: #007bff;
	color: $universalWhite;
	border: none;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	text-align: center;
	line-height: 60px;
	font-size: 14px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	cursor: pointer;
	z-index: 1000;
}
.loader {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	display: block;
	margin: 4px 20px;
	position: relative;
	color: $universalBlack;
	box-sizing: border-box;
	animation: animloader 0.7s linear infinite alternate;
	left: 10px;
}
.chat-bot-error-message {
	font-size: 12px;
	padding-left: 23.04px;
	padding-top: 4px;
	color: #800000;
	margin-bottom: 8px;
}
.exceed-error-message {
	font-size: 12px;
	line-height: 20px;
	font-weight: 400;
	text-align: center;
	color: #393939;
	margin: 38px 42px 24px;
	max-width: 352px;
}
.chat-bot-image {
	width: 32px;
	height: 32px;
	border-radius: 32px;
	-o-object-fit: cover;
	object-fit: cover;
}
@keyframes animloader {
	0% {
		box-shadow: -28px -5px, -10px 0, 8px 0;
	}
	33% {
		box-shadow: -28px 0px, -10px -5px, 8px 0;
	}
	66% {
		box-shadow: -28px 0px, -10px 0, 8px -5px;
	}
	100% {
		box-shadow: -28px 0, -10px 0, 8px 0;
	}
}
@keyframes fadeUp {
	from {
		transform: translateY(100px);
		opacity: 0;
	}
	to {
		transform: translateY(0px);
		opacity: 1;
	}
}

/*Responsivness*/

@media screen and (max-width: 991px) {
	.chat-bot {
		right: 40px !important;
		bottom: 60px !important;
		.bot-popup {
			width: 92px !important;
			height: 56px !important;
		}
		.bot-chat {
			width: 436px !important;
			height: 559px !important;
			bottom: 80px !important;
			.bot-header {
				padding: 24px 24px 22px 30px !important;
				.chat-box-header {
					gap: 11px !important;
				}
				.chat-box-heading {
					font-size: 16px !important;
					line-height: 19px !important;
				}
				.chat-box-subHeading {
					font-size: 12px !important;
					line-height: 13px !important;
				}
			}
			.bot-body {
				height: calc(100% - 72px) !important;
				.msgs-scroller {
					padding: 23px 35px 14px 22px !important;
					height: calc(100% - 136px) !important;
				}
				.write-msg {
					margin: 0 35px 0 22px !important;
					gap: 19px !important;
					padding-right: 26px !important;
					input {
						padding: 16px 26px !important;
						font-size: 14px !important;
						background-color: transparent;
						line-height: 18px;
					}
					img {
						height: 20px;
						width: 20px;
					}
				}
				.msgs-scroller {
					.msg-sent {
						p {
							padding: 11px 22px !important;
							font-size: 14px !important;
							line-height: 17px !important;
							margin-bottom: 11px !important;
						}
					}
					.msg-receive {
						span {
							padding: 11px 22px !important;
							width: --webkit-fill-available;
							font-size: 14px !important;
							line-height: 17px !important;
							margin-bottom: 7px !important;
						}
					}
				}
				.powered-by {
					font-size: 12px !important;
					line-height: 14.5008px !important;
					padding: 8px 0 8px 22px;
				}
			}
		}
	}
	.chat-bot-error-message {
		font-size: 12px !important;
		line-height: 12px !important;
		padding-left: 22px !important;
		padding-top: 2px !important;
		margin-bottom: 2px !important;
	}
}

@media screen and (max-width: 769px) {
	.chat-bot {
		right: 40px !important;
		bottom: 60px !important;
		.bot-popup {
			width: 92px !important;
			height: 56px !important;
		}
		.bot-chat {
			width: 360px !important;
			height: 420px !important;
			bottom: 80px !important;
			.bot-header {
				padding: 13px 25px !important;
				.chat-box-header {
					gap: 9px !important;
				}
				.chat-box-heading {
					font-size: 14px !important;
					line-height: 14px !important;
				}
				.chat-box-subHeading {
					font-size: 12px !important;
					line-height: 12px !important;
				}
			}
			.bot-body {
				height: calc(100% - 85.68px) !important;
				.msgs-scroller {
					padding: 14px !important;
					height: calc(100% - 75px) !important;
					.msg-sent p {
						padding: 10px 12px !important;
						width: --webkit-fill-available;
						font-size: 12px !important;
						line-height: 14px !important;
						margin-bottom: 7px !important;
					}
					.msg-receive span {
						padding: 10px 12px !important;
						width: --webkit-fill-available;
						font-size: 12px !important;
						line-height: 14px !important;
						margin-bottom: 7px !important;
					}
				}
				.write-msg {
					margin: 0 14px !important;
					gap: 15px !important;
					padding-right: 14px !important;
					input {
						padding: 16px 24px !important;
						font-size: 14px !important;
						background-color: transparent;
						line-height: 17px;
					}
				}
				.powered-by {
					font-size: 12px !important;
					padding: 8px 0 8px 14px !important;
				}
			}
		}
	}
	.chat-bot-error-message {
		font-size: 12px !important;
		padding-left: 14px !important;
		padding-top: 8px !important;
	}
}

@media screen and (max-height: 720px) {
	.chat-bot {
		bottom: 32px !important;
	}
	.bot-chat {
		width: 368px !important;
		height: 400px !important;
	}
}

@media screen and (max-width: 480px) {
	.chat-bot {
		right: 20px !important;
		bottom: 40px !important;
		.bot-popup {
			width: 92px !important;
			height: 100% !important;
		}
		.bot-chat {
			width: 288px !important;
			height: 350px !important;
			bottom: 60px !important;
			.bot-header {
				padding: 10px 10px 10px 20px !important;
				.chat-box-header {
					gap: 8px !important;
				}
				.chat-box-heading {
					font-size: 14px !important;
					line-height: 14px !important;
				}
				.chat-box-subHeading {
					font-size: 12px !important;
					line-height: 12px !important;
				}
			}
			.bot-body {
				height: calc(100% - 76px) !important;
				.msgs-scroller {
					padding: 20px !important;
					height: calc(100% - 75px) !important;
					.msg-sent p {
						padding: 10px 12px !important;
						width: --webkit-fill-available;
						font-size: 10px !important;
						line-height: 12px !important;
						margin-bottom: 6px !important;
					}
					.msg-receive span {
						padding: 10px 12px !important;
						width: --webkit-fill-available;
						font-size: 10px !important;
						line-height: 12px !important;
						margin-bottom: 6px !important;
					}
				}
				.write-msg {
					margin: 0 20px !important;
					gap: 14px !important;
					padding-right: 12px !important;
					img {
						height: 17px;
						width: 17px;
					}
				}
				.powered-by {
					font-size: 11px !important;
					padding: 4px 0 4px 20px !important;
				}
			}
		}
	}
	.chat-bot-error-message {
		font-size: 11px !important;
		padding-left: 10px !important;
		padding-top: 4px !important;
	}
	.display-time {
		margin-left: 20px;
	}
}

@media only screen and (max-width: 480px) {
	.active {
		width: 100% !important;
		height: 100% !important;
		right: 0px !important;
		bottom: -60px !important;
		.bot-popup {
			width: 100% !important;
			height: 100% !important;
		}
	}
	.chat-bot {
		.bot-chat {
			&.animation {
				width: 100% !important;
				height: 100% !important;
			}
			&.position-left {
				left: -20px !important;
			}
		}
	}
}

// iPhone 14 Pro Max
@media only screen and (width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {
	.active {
		width: 100% !important;
		height: 100% !important;
		right: 0px !important;
		bottom: 0px !important;
		.bot-popup {
			width: 100% !important;
			height: 100% !important;
		}
	}
	.chat-bot {
		.bot-chat {
			bottom: 0px !important;
			&.animation {
				width: 100% !important;
				height: 100% !important;
			}
			&.position-left {
				left: -20px !important;
			}
		}
	}
}

// iPhone 13 Pro Max
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
	.active {
		width: 100% !important;
		height: 100% !important;
		right: 0px !important;
		bottom: 0px !important;
		.bot-popup {
			width: 100% !important;
			height: 100% !important;
		}
	}
	.chat-bot {
		.bot-chat {
			bottom: 0px !important;
			&.animation {
				width: 100% !important;
				height: 100% !important;
			}
			&.position-left {
				left: -20px !important;
			}
		}
	}
}

// iPhone 6, 7, 8 Plus
@media only screen and (width: 414px) and (-webkit-device-pixel-ratio: 2) {
	.active {
		width: 100% !important;
		height: 100% !important;
		right: 0px !important;
		bottom: -84px !important;
		.bot-popup {
			width: 100% !important;
			height: 100% !important;
		}
	}
	.chat-bot {
		.bot-chat {
			&.animation {
				width: 100% !important;
				height: 100% !important;
			}
			&.position-left {
				left: -20px !important;
			}
		}
	}
}

// iPhone 14 Pro
@media only screen and (width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {
	.active {
		width: 100% !important;
		height: 100% !important;
		right: 0px !important;
		bottom: 0px !important;
		.bot-popup {
			width: 100% !important;
			height: 100% !important;
		}
	}
	.chat-bot {
		.bot-chat {
			&.animation {
				width: 100% !important;
				height: 100% !important;
			}
			&.position-left {
				left: -20px !important;
			}
		}
	}
}

// iPhone 14, iPhone 13 and iPhone 13 Pro
@media only screen and (width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
	.active {
		width: 100% !important;
		height: 100% !important;
		right: 0px !important;
		bottom: -84px !important;
		.bot-popup {
			width: 100% !important;
			height: 100% !important;
		}
	}
	.chat-bot {
		.bot-chat {
			bottom: 82px !important;
			&.animation {
				width: 100% !important;
				height: 100% !important;
			}
			&.position-left {
				left: -20px !important;
			}
		}
	}
}

// iPhone 13 Mini
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
	.active {
		width: 100% !important;
		height: 100% !important;
		right: 0px !important;
		bottom: -90px !important;
		.bot-popup {
			width: 100% !important;
			height: 100% !important;
		}
	}
	.chat-bot {
		.bot-chat {
			&.animation {
				width: 100% !important;
				height: 100% !important;
			}
			&.position-left {
				left: -20px !important;
			}
		}
	}
}

// iPhone X
@media only screen and (device-width: 375px) and (device-height: 632px) and (-webkit-device-pixel-ratio: 3) {
	.active {
		width: 100% !important;
		height: 100% !important;
		right: 0px !important;
		bottom: -90px !important;
		.bot-popup {
			width: 100% !important;
			height: 100% !important;
		}
	}
	.chat-bot {
		.bot-chat {
			&.animation {
				width: 100% !important;
				height: 100% !important;
			}
			&.position-left {
				left: -20px !important;
			}
		}
	}
}
